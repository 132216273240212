html, body {
  font-optical-sizing: auto;
  background-color: #f0f8ff;
  width: 100%;
  height: 100%;
  font-family: Google Sans, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
}

@media screen and (width >= 768px) {
  html, body {
    font-size: 1.7em;
  }
}

body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

body .lottie-container {
  width: 100%;
  margin-top: -100px;
}

body .shop-container {
  opacity: 0;
  text-align: center;
  transition: opacity .5s ease-out;
  position: absolute;
  top: 100px;
}

body .shop-container a:link, body .shop-container a:visited {
  color: #000;
}

body .shop-container a:hover {
  color: #00f;
}

body .shop-container.show {
  opacity: 1;
}

body .email-container {
  opacity: 0;
  text-align: center;
  transition: opacity .5s ease-out;
  position: absolute;
  bottom: 100px;
}

body .email-container a:link, body .email-container a:visited {
  color: #000;
}

body .email-container a:hover {
  color: #00f;
}

body .email-container.show {
  opacity: 1;
}
/*# sourceMappingURL=index.d762b6f5.css.map */
