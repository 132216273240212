html,
body {
  width: 100%;
  height: 100%;
  background-color: aliceblue;

  font-family: "Google Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  @media screen and (min-width: 768px) {
    font-size: 1.7em;
  }
}
body {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .lottie-container {
    width: 100%;
    margin-top: -100px;
  }
  .shop-container {
    position: absolute;
    top: 100px;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    text-align: center;
    a:link {
      color: black;
    }
    a:visited {
      color: black;
    }
    a:hover {
      color: blue;
    }
    &.show {
      opacity: 1;
    }
  }
  .email-container {
    position: absolute;
    bottom: 100px;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    text-align: center;
    a:link {
      color: black;
    }
    a:visited {
      color: black;
    }
    a:hover {
      color: blue;
    }
    &.show {
      opacity: 1;
    }
  }
}
